@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes fadeLeftIn {
    0% {
        transform: translateX(5px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}